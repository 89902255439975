<template>
  <div>
      <CCard>
      <CCardHeader>
        <CIcon name="cil-settings" /><strong>Allocate Plan</strong>
      </CCardHeader>

       <CCardBody>
           <CRow class="col-md-12">
                  <label class="col-md-2">User : </label>
                    <CSelect
                    class="col-md-3"
                    :options="user_opt"
                    :value.sync="input.userid"
                    size="sm"
                    placeholder='select'
                    />
              </CRow>
              <CRow class="col-md-12">
                  <label class="col-md-2">Plans : </label>
                    <CSelect
                    class="col-md-3"
                    track-by="id"
                    :options="plan_opt"
                    :value.sync="input.plan_name"
                    size="sm"
                    placeholder='select'
                    @change="checkPlan()"
                    />
              </CRow>

               <!-- <CRow class="col-md-12">
                  <label class="col-md-2">Status : </label>
                    <CSelect
                    class="col-md-3"
                    :options="status_opt"
                    :value.sync="input.status"
                    size="sm"
                    placeholder='select'
                    />
              </CRow> -->

              <CRow class="col-md-12">
                <label class="col-md-2">Expiry Date :</label>
                <label class="col-md-2">{{changeDateFormate(allocatedate)}}</label>
                <CButton class="float-right"
                        type="submit"
                        v-on:click="show_date == false ?show_date= true : show_date =false" color="info"
                >Change Date</CButton>
              </CRow>

              <CRow class="mt-2" v-show="show_date">
                <label class="col-md-2"></label>
                <date-time-picker
                v-if="renderComponent"
                class="col-md-3 col-3"
                :singleDate="true"
                :startDate="allocatedate"
                @onChange="dateFilter"
                size="sm"
              />
              </CRow>

              <CRow class="col-md-12 mt-4">
                  <CCol class="col-md-5">
                  <center>
                  <CButton class="float-right"
                        type="submit"
                        v-on:click="onSubmit()"
                        variant="" color="success"
                    >{{edit_allocate_plan? "Save" : "Submit"}}</CButton></center>
                  </CCol>
              </CRow>
       </CCardBody>
      </CCard>
      <CCard>
      <CCardHeader>
        <CIcon name="cil-settings" /><strong>Details</strong>
      </CCardHeader>
      <CCardBody>
          <div class="card-header-actions">
            <small class="text-muted"
              >
        <multiselect
                size="sm"
              :limit="2"
              :clear-on-select="false"
              :close-on-select="true"
              :multiple="false"
              :options="perPage"
              :preselect-first="false"
              :preserve-search="false"
              @input="checkPage()"
              placeholder="PerPage"
              v-model="tableperpage"
            ></multiselect></small
            ></div>
      <!-- Main table element -->
      <CDataTable
      :items="items"
      :fields="fields"
      :dark="false"
      :items-per-page="tableperpage"
      pagination
      sorter
      hover
      striped
      border
      small
      fixed
      table-filter
      light
    >
    <template #planid="{item}">
        <td>
          <span v-if="plan_opt.find(t=>t.id == item.planid)">{{plan_opt.find(t=>t.id == item.planid).value}}
          </span>
        </td>
    </template>

      <template #status="{item}">
          <td>
              <span v-if="item.status == true">Active</span>
              <span v-if="item.status == false">Inactive</span>
          </td>
      </template>

      <template #createdAt="{item}">
        <td>{{changeDateFormate(item.createdAt)}}</td>
      </template>

      <template #expiry_date="{item}">
        <td>{{changeDateFormate(item.expiry_date)}}</td>
      </template>

      <template #isActive="{item}">
        <td v-if="item.role != 'manager'">
        <span @click="update(item)">
        <CImg v-if="item.isactive == true" src="img/logo/push.png" class="img-fluid ml-1" title="Inactive" :pressed.sync="item.status"
          style="cursor: pointer;"
          ></CImg>
        <CImg v-if="item.isactive == false" src="img/logo/play.png" class="img-fluid ml-1" title="Active" :pressed.sync="item.status"
            style="cursor: pointer;"
        ></CImg>
        </span>
         <span v-if="item.isactive == true"> Deactivate</span>
        <span v-if="item.isactive == false"> Activate</span>
        
        </td>
      </template>
    <template #days_left="{item}">
        <td>
          <span v-if="findleftDays(new Date(),item.expiry_date) < 0">
            Expired
          </span>
          <span v-else>
            {{findleftDays(new Date(),item.expiry_date)}}
          </span>
        </td>
    </template>
    <template #action="{item}">
      <td>
      <span v-if="item.status == true" @click="Edit(item)">
          <CImg src="img/logo/edit.png" class="img-fluid" title="Edit"
                             style="cursor: pointer;"
                             ></CImg>
          </span>
          </td>
    </template>
    </CDataTable>
    </CCardBody></CCard>


  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import ManagerService from './../../services/managerControlService'
import "@lazy-copilot/datetimepicker/dist/datetimepicker.css";
import { DateTimePicker } from "@lazy-copilot/datetimepicker";
  export default {
    components: {Multiselect,DateTimePicker},
    data() {
      return {
        renderComponent: true,
        show_date:false,
        allocatedate: new Date(),
        edit_allocate_plan:false,
        input:{expiry_date:''},
        tableperpage:20,
        perPage:[20,50,100],
        items: [],
        fields: [
          { key: 'userid', label: 'User'},
          {
            key: 'planid',
            label: 'Plan',
          },
          {
            key: 'createdAt',
            label: 'CreatedAt',
          },
          {
            key: 'expiry_date',
            label: 'Expiry Date',
          },
          {
            key: 'days_left',
            label: 'Days left',
          },
          {
            key: 'status',
            label: 'Status',
          },
          {
            key: 'action',
            label: 'Action',
          },
        ],
        totalRows: 1,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: ''
        },
        users:[],
        plan_opt:[],
        user_opt:[],
        plan_data:[],
        status_opt:[{ label: "Active", value: true },
                        { label: "Inactive", value: false }],
      }
    },
    computed: {
      
    },
   async mounted() {
      if(window.localStorage.getItem('user') == 'null' || window.localStorage.getItem('user') != 'manager') {
        alert('Please Enter User Name and Password')
          window.localStorage.setItem("user", null);
          window.localStorage.setItem("status", null);
          window.localStorage.setItem("email", null);
        this.$router.push('/pages/login')
      }
      if(localStorage.getItem("user_table") != null)
      this.tableperpage = parseInt(localStorage.getItem("user_table"));
      this.getUsers()
      this.getPlans()
      this.getAllocatePlans()
      
    },
    methods: {
      treatAsUTC(date) {
          var result = new Date(date);
          result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
          return result;
      },
      findleftDays(startDate,endDate){
        var millisecondsPerDay = 24 * 60 * 60 * 1000;
        var t = Math.floor(this.treatAsUTC(endDate) / millisecondsPerDay) - Math.floor(this.treatAsUTC(startDate) / millisecondsPerDay);
        return t
      },
      changeDateFormate(d){
      var p = new Date(d);
      let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(p);
      let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(p);
      let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(p);
      let hh = new Intl.DateTimeFormat('en', { hour: '2-digit' }).format(p);
      var dd
      if(this.change_date){
       dd = `${ye} ${mo} ${da} , ${hh}`;
      }
      else{ dd = `${ye} ${mo} ${da}` }
      return  dd
    },
      Edit(data){
            this.show_date =false
            this.edit_allocate_plan = true
            this.input = data
            this.input.plan_name = this.plan_data.filter(t=>t._id == data.planid)
            this.input.plan_name = this.input.plan_name.map(p=>p.plan_name)
            this.input.plan_name = this.input.plan_name[0]
            this.input.expiry_date = new Date(data.expiry_date)
            this.allocatedate = this.input.expiry_date
            this.renderComponent = false;
            this.$nextTick(() => {
            this.renderComponent = true;
            });
      },
      dateFilter(data) {
      this.allocatedate = data.startDate
      this.input.expiry_date = this.allocatedate
    },
      checkPlan(){
        let plan = this.plan_data.find(t=>  t.plan_name == this.input.plan_name)
        this.input.planid = plan._id
        var date = new Date();
        switch(plan.validity){
          case 'Weekly': this.input.expiry_date = new Date(date.getFullYear(), date.getMonth(), date.getDate()+7)
          break;
          case 'Monthly': this.input.expiry_date = new Date(date.getFullYear(), date.getMonth(), date.getDate()+30)
          break;
          case 'Quarterly': this.input.expiry_date = new Date(date.getFullYear(), date.getMonth(), date.getDate()+90)
          break;
          case 'Yearly': this.input.expiry_date = new Date(date.getFullYear(), date.getMonth(), date.getDate()+365)
          break;
          default : this.input = date
        }
        this.dateFilter({startDate:new Date(this.input.expiry_date.setHours(23,59))})
        this.renderComponent = false;
        this.$nextTick(() => {
        this.renderComponent = true;
        });
        // this.allocatedate = this.input.expiry_date
      },
   async onSubmit(){
        if(this.Validate()){
               try {
                 
                var response
                if(this.edit_allocate_plan){
                  
                var payload = { _id:this.input._id,userid:this.input.userid, planid:this.input.planid,
                               expiry_date:this.input.expiry_date} 
                response = await ManagerService.EditAllocatePlans(payload);
                }
                else{
                var payload = {userid:this.input.userid, planid:this.input.planid,
                              expiry_date:this.input.expiry_date}
                response = await ManagerService.AllocatePlans(payload);
                }
                if(response.result){
                    Swal.fire({title: 'Success!',text: response.message,icon: 'success',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
                }
                else{
                Swal.fire({title: 'Error!',text: response.message, icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
                }
                this.edit_allocate_plan = false
                this.input = {}
                this.getPlans()
                this.getAllocatePlans()
                } catch (e) {
                    console.log('connection error')
                }
                } 
               else{
                Swal.fire({title: 'Error!',text: "All details Required!",icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
                } 
    },
    Validate(){
            if(this.input.planid  && this.input.expiry_date && this.input.userid){
                return true
            }
            else{
                return false
            }
        },
    async getPlans(){
        let res = await ManagerService.getplan();
        this.plan_data = res.message
        this.plan_data = this.plan_data.filter(t => t.status) 
        this.plan_opt = this.plan_data.map(p=>{ return {value: p.plan_name, id:p._id} })
             
    },
      checkPage(){
          localStorage.setItem("user_table", this.tableperpage);
        },
       async getUsers(){
            let response
        try {
            response = await ManagerService.getuser()
            this.users = response.message.filter(t=>{
            if(t.role != 'manager')
            return t
            })
            this.user_opt = this.users.map(m =>  m.email)
        } catch(e){
            console.error("Error in login")
            console.log(e)
        }
        },
        async getAllocatePlans(){
            var response
        try {
            response = await ManagerService.getAllocatePlans()
            this.items = response.message
            this.totalRows = this.items.length
        } catch(e){
            console.error("Error in login")
            console.log(e)
        }
        }
    }
  }
</script>

<style>
  .b_card{
    background-color: white;
    /* box-shadow: rgb(136, 136, 136) 2px 5px 7px 2px; */
  }

  .bg_page {
    background: white;
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 20px;
    /*position: fixed;*/
    /*background: rgb(2, 0, 36);*/
    /*background: linear-gradient(152deg, rgba(2, 0, 36, 1) 0%, rgba(15, 15, 126, 1) 49%, rgba(0, 212, 255, 1) 100%);*/
  }
  .logout:hover{
    background-color: #b82e2b;
  }
  .v_tag{
    overflow-y: scroll;
    max-height: 100px;
  }

</style>
